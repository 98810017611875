<template>
  <div
    class="d-flex align-items-center justify-content-center login-background"
  >
    <b-card border-variant="light" class="login-card mx-3 mx-sm-0">
      <div class="d-flex justify-content-between mb-3">
        <b-card-title class="mb-0 mr-4">
          {{
            newUser
              ? $t("resetPasswordForm.titleNew")
              : $t("resetPasswordForm.title")
          }}
        </b-card-title>
        <img :src="logo" alt="qXPLR Logo" class="logo" />
      </div>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(setNewPassword)">
          <ValidationProvider
            v-slot="{ errors, valid }"
            name="New Password"
            rules="required|min:6|password:@Confirmation"
          >
            <b-form-group id="group-password" label-for="new-password">
              <template v-slot:label>
                {{ $t("resetPasswordForm.newPassword") }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="new-password"
                v-model="form.new"
                :state="getValidationState(errors, valid)"
                autocomplete="new-password"
                data-cy="new-password"
                type="password"
              >
              </b-form-input>
              <b-form-invalid-feedback id="live-feedback-password">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, valid, invalid }"
            name="Confirmation"
            rules="required|min:6|password:@New Password"
          >
            <b-form-group
              id="group-password-confirm"
              label-for="password-confirm"
            >
              <template v-slot:label>
                {{ $t("resetPasswordForm.confirmPassword") }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="password-confirm"
                v-model="form.confirm"
                :state="getValidationState(errors, valid)"
                autocomplete="new-password"
                data-cy="password-confirm"
                type="password"
              />
              <b-form-invalid-feedback id="live-feedback-password-confirm">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="d-flex align-items-center justify-content-between">
              <b-link to="/login">
                {{ $t("resetPasswordEmail.back") }}
              </b-link>
              <b-button
                :disabled="invalid || loading"
                class="gradient-button-outline"
                data-cy="new-password-submit"
                pill
                type="submit"
              >
                {{
                  newUser
                    ? $t("resetPasswordForm.set")
                    : $t("resetPasswordForm.reset")
                }}
              </b-button>
            </div>
          </ValidationProvider>
        </b-form>
      </ValidationObserver>
    </b-card>
    <Footer />
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { min, required } from "vee-validate/dist/rules";
import { hash_password } from "@/util/hashing";
import qXPLR_logo from "@/assets/images/logo-qxplr-black-transparent.png";
import Footer from "@/components/Footer.vue";

extend("min", min);
extend("required", {
  ...required,
  message: "{_field_} is required",
});
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

export default {
  components: {
    Footer,
    ValidationProvider,
    ValidationObserver,
  },
  name: "Password",
  data() {
    return {
      form: {
        new: "",
        confirm: "",
      },
      loading: false,
      logo: qXPLR_logo,
      newUser: this.$route.query.new,
    };
  },
  methods: {
    // Set new password
    async setNewPassword() {
      const newHashed = await hash_password(this.form.new);
      try {
        await this.$http.put(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/${
            this.newUser ? "register" : "reset-password"
          }`,
          {
            password: newHashed,
            ...(this.newUser && { registrationToken: this.$route.query.token }),
            ...(!this.newUser && { resetToken: this.$route.query.token }),
          }
        );

        this.$root.$bvToast.toast("Password reset successfully!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });

        await this.$router.push({ path: "/login" });
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // Get validation Errors
    getValidationState(errors, valid) {
      return errors[0] ? false : valid ? true : null;
    },
  },
};
</script>
